import { Controller } from '@hotwired/stimulus'
import * as csrf from 'libs/csrf'
import { isEqual } from 'libs/form_data'

export default class extends Controller {
  static targets = ['savedAt']
  static values = { url: String }

  connect() {
    this.formData = new FormData(this.element)
    this.schedule()
  }

  disconnect() {
    clearTimeout(this.timer)
  }

  schedule() {
    this.timer = setTimeout(() => {
      if (!this.tryAutoSave()) {
        this.schedule()
      }
    }, 2000)
  }

  tryAutoSave() {
    if (this.saving) {
      return false
    }

    const currentFormData = new FormData(this.element)
    // NOTE: authenticity_token が初回に変更されたと判定されてしまうので除外している。_method はおまけ
    if (isEqual(this.formData, currentFormData, { exclude: ['_method', 'authenticity_token'] })) {
      return false
    }

    this.saving = true
    this.formData = currentFormData
    this.autosave().then(() => {
      this.saving = false
      this.updateSavedAt()
      this.schedule()
    })
    return true
  }

  autosave() {
    return fetch(this.urlValue, {
      method: 'POST',
      body: this.formData,
      headers: {
        'X-CSRF-TOKEN': csrf.token()
      }
    })
  }

  updateSavedAt() {
    this.savedAtTarget.textContent = `Auto saved at ${new Date().toLocaleString()}`
  }
}
