import { Controller } from '@hotwired/stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.tagify = new Tagify(this.element, this.options)
  }

  disconnect() {
    this.tagify.destroy()
  }

  get options() {
    return {
      ...this.optionsValue,
      originalInputValueFormat: (items) => items.map(item => item.value).join(',')
    }
  }
}
