export function isEqual(formData1, formData2, options = {}) {
  const exclude = options['exclude'] || []
  for (const [k, v] of formData1.entries()) {
    if (!exclude.includes(k) && formData2.get(k) !== v)
      return false
  }
  for (const [k, v] of formData2.entries()) {
    if (!exclude.includes(k) && formData1.get(k) !== v)
      return false
  }
  return true
}
